import { UserMetadata } from "@src/Interfaces/User";
import { format, parseISO } from "date-fns";
import React from "react";

type UserInfoProps = {
	metadata?: UserMetadata;
};

const UserInfo = ({ metadata }: UserInfoProps) => {
	if (!metadata) return null;

	return (
		<div className="user-info-main-container">
			<div className="row">
				<p>
					<b>Tipo de sangre: </b>
					{metadata?.bloodType}
				</p>
			</div>
			<div className="row">
				<p>
					<b>Alergias: </b>
					{metadata?.allergies === "si" ? "Sí" : "No"}
					{metadata?.allergiesDescription ? `, ${metadata?.allergiesDescription}` : ""}
				</p>
			</div>
			<div className="row">
				<p>
					<b>Embarazo: </b>
					{metadata?.pregnancy === "si" ? "Sí" : "No"}
				</p>
			</div>
			{metadata?.pregnancyPossibleBirthdate && (
				<div className="row">
					<p>
						<b>Posible fecha de parto: </b>
						{format(parseISO(metadata?.pregnancyPossibleBirthdate), "dd/MM/yyyy")}
					</p>
				</div>
			)}
			<div className="row">
				<p>
					<b>Lesión: </b>
					{metadata?.injury === "si" ? "Sí" : "No"}
					{metadata?.injuryDescription ? `, ${metadata?.injuryDescription}` : ""}
				</p>
			</div>
			<div className="row mt-4">
				<h3 className="fw-bolder">
					<span className="text-danger fs-16">* </span>EMERGENCIAS
				</h3>
			</div>
			<div className="row">
				<p>
					<b>Contacto: </b>
					{metadata?.emergencyContact}
				</p>
			</div>
			<div className="row">
				<p>
					<b>Celular: </b>
					{metadata?.emergencyContactPhone}
				</p>
			</div>
		</div>
	);
};

export default UserInfo;
