import FormikBaseForm from "@src/components/FormikBaseForm/FormikBaseForm";
import { IUser, SignUpUser } from "@src/Interfaces/User";
import { FormikHelpers, FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import wellnessLogo from "@assets/logos/wellness-logo.png";
import { boolean, object, ref, string } from "yup";
import { Link, useNavigate } from "react-router-dom";
import "./SignUp.scss";

import { SignUpFormValues } from "./types/Types";
import StepOne from "./components/StepOne";
import StepTwo from "./components/StepTwo";
import StepThree from "./components/StepThree";
import { signUpAuth } from "@src/services/AuthService";
import { isAxiosError } from "axios";
import { notifyError, notifySuccess } from "@src/utils/NotificationFunctions";
import { getAge } from "@src/utils/Dates";

const YES = "si";
const NO = "no";

type YesOrNoEs = "si" | "no";

const SignUpSchema = object({
	email: string().email("Correo no valido").required("Ingresa un correo"),
	name: string().required("Ingresa tu nombre"),
	lastName: string().required("Ingresa tu apellido"),
	phone: string()
		.required("Ingresa tu teléfono")
		.matches(/^\d{10}$/, "El teléfono debe tener 10 dígitos y solo números"),
	instagram: string(),
	gender: string().required("Selecciona tu género"),
	birthdate: string().required("Selecciona tu fecha de nacimiento"),
	pregnancy: string().required("Selecciona una opción"),
	injury: string().required("Este campo es obligatorio"),
	injuryDescription: string().when("injury", {
		is: YES,
		then: (schema) => schema.required("Es necesario que nos cuentes acerca de tu lesión"),
		otherwise: (schema) => schema,
	}),
	allergies: string().required("Este campo es obligatorio"),
	allergiesDescription: string().when("allergies", {
		is: YES,
		then: (schema) => schema.required("Es necesario que nos cuentes acerca de tus alergias"),
		otherwise: (schema) => schema,
	}),
	bloodType: string().required("Selecciona tu tipo de sangre"),
	emergencyContact: string().required("Ingresa un contacto de emergencia"),
	emergencyContactPhone: string()
		.required("Ingresa el teléfono de tu contacto de emergencia")
		.length(10, "El teléfono debe tener 10 dígitos"),
	pregnancyPossibleBirthdate: string().when("pregnancy", {
		is: YES,
		then: (schema) =>
			schema
				.required("Es necesario que nos proporciones una posible fecha de parto")
				.test("is-valid-date", "La fecha posbile de parto no puede estar en el pasado", (value) => {
					return value ? new Date(value) >= new Date() : false;
				}),
		otherwise: (schema) => schema,
	}),
	password: string()
		.min(8, "La contraseña debe tener al menos 8 caracteres")
		.required("Ingresa una contraseña"),
	confirmPassword: string()
		.oneOf([ref("password"), undefined], "Las contraseñas no coinciden")
		.required("Confirma tu contraseña"),
	terms: boolean()
		.oneOf([true], "Debes aceptar los términos y condiciones")
		.required("Debes aceptar los términos y condiciones"),
	instagramNoTag: boolean(),
});

const SignUp = () => {
	const [step, setStep] = useState<number>(1);
	const navigate = useNavigate();

	const initialValues = {
		email: "",
		password: "",
		confirmPassword: "",
		name: "",
		lastName: "",
		birthdate: "",
		phone: "",
		gender: "",
		//
		injury: "no",
		injuryDescription: "",
		pregnancy: "no",
		pregnancyPossibleBirthdate: "",
		allergies: "no",
		allergiesDescription: "",
		//
		instagram: "",
		instagramNoTag: false,
		bloodType: "",
		emergencyContact: "",
		emergencyContactPhone: "",
		terms: false,
	};

	const submitHandler = (values: SignUpFormValues, actions: FormikHelpers<SignUpFormValues>) => {
		// your submit handler here
		const userData: SignUpUser = {
			name: values.name,
			lastname: values.lastName,
			email: values.email,
			mobile_no: values.phone,
			password: values.password,
			metadata: {
				birthdate: values.birthdate,
				bloodType: values.bloodType,
				emergencyContact: values.emergencyContact,
				emergencyContactPhone: values.emergencyContactPhone,
				gender: values.gender,
				allergies: values.allergies,
				allergiesDescription: values.allergiesDescription,
				injury: values.injury as YesOrNoEs,
				injuryDescription: values.injuryDescription,
				instagram: values.instagram,
				pregnancy: values.pregnancy as YesOrNoEs,
				pregnancyPossibleBirthdate: values.pregnancyPossibleBirthdate,
				terms: values.terms,
				instagramNoTag: values.instagramNoTag,
			},
		};

		// TODO: Send userData to your API
		const age = getAge(values.birthdate);
		if (
			age >= 18 ||
			(age < 18 &&
				window.confirm(
					"Es necesario pasar a la recepción de tu sucursal para llevar un formato para el acceso a menores de edad. ¿Deseas continuar?"
				) === true)
		) {
			handleSignUp(userData, actions);
		}
	};

	const handleSignUp = async (userData: Partial<IUser>, actions: FormikHelpers<SignUpFormValues>) => {
		// your sign up logic here
		try {
			const res = await signUpAuth(userData);
			notifySuccess("¡Te has registrado exitosamente!");
			navigate("/login");
		} catch (error: unknown) {
			console.error(error);
			actions.setSubmitting(false);

			let message = "Ocurrió un error al registrarte";

			if (isAxiosError(error)) {
				message = error.response?.data?.message || message;
			} else if (error instanceof Error) {
				message = error.message;
			}

			notifyError(message);
		}
	};

	// each step of the form should be a function that returns the JSX for that step and receives the formik props as an argument
	// they must be 3 steps in total
	// each step should have more than one field
	// there should be an input for each property in the SignUpFormValues interface
	// the inputs should be distributed in the 3 steps

	return (
		<div className="signup-form-container">
			<img className="logo" src={wellnessLogo} alt="wellness logo" />
			<h2>Datos de registro</h2>
			<FormikBaseForm<SignUpFormValues>
				className=""
				initialValues={initialValues}
				validationSchema={SignUpSchema}
				onSubmit={submitHandler}
			>
				{(formik) => (
					<>
						{formik.submitCount > 0 && formik.errors && Object.keys(formik.errors).length > 0 && (
							<div className="form-row errors">
								{Object.keys(formik.errors).map((key) => (
									<p key={key} className="text-danger">
										{formik.errors[key as keyof SignUpFormValues]}
									</p>
								))}
							</div>
						)}
						{step === 1 && <StepOne formik={formik} />}
						{step === 2 && <StepTwo formik={formik} />}
						{step === 3 && <StepThree formik={formik} />}
						<div className="form-row buttons-container">
							{step > 1 && (
								<button type="button" className="btn secondary" onClick={() => setStep((prev) => prev - 1)}>
									Anterior
								</button>
							)}
							{step < 3 && (
								<button type={"button"} className="btn primary" onClick={() => setStep((prev) => prev + 1)}>
									Siguiente
								</button>
							)}
							{step === 3 && (
								<button type="submit" className="btn primary" disabled={false}>
									Registrarme
								</button>
							)}
						</div>
					</>
				)}
			</FormikBaseForm>
			<p className="fs-14">
				Ya tienes cuenta?{" "}
				<Link className="fs-14" to="/login">
					Inicia sesión
				</Link>
			</p>
		</div>
	);
};

export default SignUp;
