import { ReactNode } from "react";
import { Form, Formik, FormikHelpers, FormikProps, FormikValues } from "formik";
import { Schema } from "yup";

type FormikBaseFormProps<T> = {
	initialValues: T;
	validationSchema: Schema<T>;
	onSubmit: (values: T, actions: FormikHelpers<T>) => void;
	children: ReactNode | ((props: FormikProps<T>) => ReactNode);
	id?: string;
	className?: string;
};

const FormikBaseForm = <T extends FormikValues>({
	initialValues,
	validationSchema,
	onSubmit,
	children,
	id,
	className,
}: FormikBaseFormProps<T>) => {
	return (
		<Formik<T> initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
			{(formik) => {
				return (
					<Form id={id} className={className}>
						{typeof children === "function" ? children(formik) : children}
					</Form>
				);
			}}
		</Formik>
	);
};

export default FormikBaseForm;
