import { differenceInYears, format, parseISO } from "date-fns";
import { es } from "date-fns/locale";

export function formatStringDate(dateString: string) {
	const date = new Date(dateString); // or any other date
	const formattedDate = format(date, "dd/MM/yyyy");
	return formattedDate;
}

export function fullStringDate(dateString: string) {
	const date = new Date(dateString);
	const formattedDate = format(date, "d - MMMM - yyyy", { locale: es });
	return formattedDate;
}

export function ISOtoStandarDate(dateString: string) {
	try {
		const date = parseISO(dateString);

		// Format the Date object into 'dd/mm/yyyy' format
		const formattedDate = format(date, "dd/MM/yyyy");
		return formattedDate;
	} catch (error) {
		console.error(error);
		return "";
	}
}

export function formatCancelled(date: string) {
	try {
		const isoDate = new Date(date);
		const formattedDate = format(
			new Date(isoDate.getTime() + isoDate.getTimezoneOffset() * 60000),
			"EEEE, dd/MM/yyyy hh:mm:ss a",
			{
				locale: es,
			}
		);

		return formattedDate;
	} catch (error) {
		console.error(error);
		return "";
	}
}

export function getAge(birthdate: string) {
	const today = new Date();
	const birthDate = new Date(birthdate);
	const age = differenceInYears(today, birthDate);
	return age;
}
