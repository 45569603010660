import { useUserContext } from "@src/context/UserContext";
import "./NotificationContainer.scss";
import { useEffect, useState } from "react";
import { getNotifications } from "@src/services/UserService";
import { ISOtoStandarDate } from "@src/utils/Dates";

interface NotificationContainerPropsI {
	show: boolean;
	notifications: Array<any>;
}

function NotificationContainer({ show, notifications }: NotificationContainerPropsI) {

	return (
		<div className={`notification-container light-shadow ${show ? "open" : ""}`}>
			<div className="headers border-bottom p-2">
				<h2 className="m-0">Notificaciones</h2>
			</div>
			<div className="notification-list-container p-3">
				{notifications.map((notification) => {
					return (
						<div className="border-bottom p-2" key={notification.id}>
							<h4 style={{color: "#8e8e8e", fontSize: "1.4rem"}}>Fecha: {ISOtoStandarDate(notification.createdAt)}</h4>
							<h3 className="fs-14">{notification.message}</h3>
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default NotificationContainer;
