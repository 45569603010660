import Progress from "../Progress/Progress";
import { differenceInDays, parseISO, isWithinInterval, parse } from "date-fns";
import "./MembershipCard.scss";
import { useEffect, useState } from "react";
import { fullStringDate } from "@src/utils/Dates";
import { fetchOpenInvoiceByMembership } from "@src/services/UserService";
import { useUserContext } from "@src/context/UserContext";
import { removeKeywords } from "@src/utils/CommonFunctions";

interface MembershipCardPropsI {
	membership: any;
}

function MembershipCard(props: MembershipCardPropsI) {
	const { membership } = props;
	const dateToday = new Date();
	const dateEnd = parseISO(membership.endDate);
	const startDate = parseISO(membership.startDate);
	const { userToken, user } = useUserContext();
	const [canRenovate, setCanRenovate] = useState<boolean>(false);

	const leftDays = differenceInDays(dateEnd, dateToday);
	const totalDays = differenceInDays(dateEnd, startDate);
	const passedDays = totalDays - leftDays < 0 ? 0 : totalDays - leftDays;

	const getOpenInvoice = async () => {
		if (membership.isHorarioFijo) {
			fetchOpenInvoiceByMembership(userToken, membership.id, user.id).then((res) => {
				if (res && res.status === 200) {
					const openInvoices = res.data.filter((e: any) => e.status === "open");

					if (openInvoices.length) {
						const invoice = openInvoices[0];
						const startDateString = invoice.date; // DD/MM/YYYY
						const endDateString = invoice.dueDate; // DD/MM/YYYY

						const startDate = parse(startDateString, "dd/MM/yyyy", new Date());
						const endDate = parse(endDateString, "dd/MM/yyyy", new Date());
						const today = new Date();

						const isTodayInBetween = isWithinInterval(today, {
							start: startDate,
							end: endDate,
						});

						setCanRenovate(isTodayInBetween);
					}
				}
			});
		}
	};

	useEffect(() => {
		getOpenInvoice();
	}, []);

	return (
		<div className="membership-card-component">
			<div className="membership-information-row">
				<div className="memberhsip-information-container">
					<div className="c-row">
						<h3 className="fs-14 fw-bold">
							{membership.lesson?.name} {membership.lesson ? "-" : ""}{" "}
							{membership.lessonId ? membership.classPayed : ""} {membership.plan?.name}{" "}
							{membership.isHorarioFijo && <span className="highlight">(Cupo fijo)</span>}
						</h3>
						{membership.location && (
							<h4 className="fs-14">
								Sucursal: <b>{removeKeywords(membership.location.name)}</b>
							</h4>
						)}
						{!membership.location && !membership.lessonId && (
							<h4 className="fs-14">
								Sucursal: <b>TODAS</b>
							</h4>
						)}
						{membership.endDate && (
							<h4>
								Vence: <b className="text-capitalize">{fullStringDate(membership.endDate)}</b>
							</h4>
						)}
						{membership.freeClass >= 0 && (
							<h4>
								{!membership.isHorarioFijo ? "Reservas" : "Reposiciones"} disponibles:{" "}
								<b>{membership.freeClass}</b>
							</h4>
						)}
					</div>
				</div>
			</div>
			{canRenovate && (
				<div className="c-row footer">
					<button
						className="btn wellness"
						onClick={() => {
							window.open(
								"https://api.whatsapp.com/send?phone=526624171867&text=¡Hola! Quiero pagar mi mensualidad de cupo fijo. Te proporciono Correo electrónico:"
							);
						}}
					>
						+ Renovar
					</button>
				</div>
			)}
		</div>
	);
}

export default MembershipCard;
