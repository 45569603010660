import React from "react";
import "./ForgotPassword.scss";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import { useState } from "react";
import { object, ref, string } from "yup";
import { ReactComponent as EmailIcon } from "@assets/icons/mail.svg";
import { sendCodePassword, setNewPassword, verifyResetPasswordCode } from "@src/services/AuthService";
import { notifyError, notifySuccess } from "@src/utils/NotificationFunctions";
import { isAxiosError } from "axios";
import { handleError } from "@src/utils/HandleError";
import { useNavigate } from "react-router-dom";

const EmailSchema = object({
	email: string().email("Ingresa un correo válido").required("Ingresa un correo"),
});

const CodeSchema = object({
	code: string()
		.trim()
		.required("Es necesario que ingreses un código")
		.matches(/^[0-9]+$/, "Sólo debes de ingresar números")
		.length(6, "El código debe de ser de 6 digitos.."),
});

const PasswordSchema = object({
	password: string()
		.required("Ingresa tu nueva contraseña")
		.min(8, "La contraseña debe de ser minimo de 8 caracteres"),
	confirmPassword: string()
		.required("Por favor confirma tu contraseña")
		.oneOf([ref("password"), ""], "Las contraseñas no coinciden"),
});

const formSchemas = [EmailSchema, CodeSchema, PasswordSchema];
const submitTexts = ["Enviar código", "Validar código", "Reestablecer contraseña"];
const subtitles = [
	"Enviaremos un código a tu cuenta para que puedas reestablecer la contraseña",
	"Ingresa el código de verificación que llego a tu correo.\nEl código solo será válido por 15 minutos.",
	"Ingresa tu nueva contraseña",
];

const ForgotPassword = () => {
	const [step, setStep] = useState(0);
	const [email, setEmail] = useState("");
	const [resetToken, setResetToken] = useState("");
	const navigate = useNavigate();

	const formik = useFormik({
		initialValues: { email: "", code: "", password: "", confirmPassword: "" },
		validationSchema: formSchemas[step],
		onSubmit: async (values, { resetForm, setSubmitting }) => {
			try {
				if (step === 0) {
					await sendCode(values.email);
					setStep(1);
					setEmail(values.email);
					notifySuccess("Código enviado exitosamente.");
				} else if (step === 1) {
					await validateCode(values.code);
					setStep(2);
					notifySuccess("Código verificado. Puede cambiar su contraseña.");
				} else if (step === 2) {
					await resetPassword(values.password);
					notifySuccess("Contraseña cambiada con éxito.");
					navigate("/login");
				}
				resetForm();
			} catch (error) {
				handleError(error);
			} finally {
				setSubmitting(false);
			}
		},
	});

	const resetPassword = async (newPassword: string) => {
		const res = await setNewPassword(newPassword, resetToken);
	};

	const validateCode = async (code: string) => {
		const res = await verifyResetPasswordCode(email, code);
		setResetToken(res?.token || "");
	};

	const sendCode = async (email: string) => {
		const res = await sendCodePassword(email);
	};

	const { errors, touched } = formik;
	return (
		<div className="forgot-password-main-container">
			<h2>Reestablecer contraseña</h2>
			<p>{subtitles[step]}</p>
			{email && <p>{email}</p>}
			<form onSubmit={formik.handleSubmit}>
				{step === 0 && (
					<div className="form-row">
						<label htmlFor="email">Correo</label>
						<div className="input-container">
							<label htmlFor="email">
								<EmailIcon />
							</label>
							<input
								id="email"
								name="email"
								type="email"
								placeholder="Correo"
								onChange={formik.handleChange}
								value={formik.values.email}
							/>
						</div>
						{touched.email && errors.email && <div className="form-error">{errors.email}</div>}
					</div>
				)}
				{step === 1 && (
					<div className="form-row">
						<label htmlFor="code">Código de verificación</label>
						<div className="input-container">
							<input
								className="code"
								type="text"
								id="code"
								name="code"
								placeholder="Ej: 384592"
								onChange={formik.handleChange}
								value={formik.values.code}
							/>
						</div>
						{touched.code && errors.code && <div className="form-error">{errors.code}</div>}
					</div>
				)}
				{step === 2 && (
					<>
						<div className="form-row">
							<label htmlFor="password">Nueva contraseña</label>
							<div className="input-container">
								<input
									className="password"
									type="password"
									id="password"
									name="password"
									placeholder="Nueva contraseña"
									onChange={formik.handleChange}
									value={formik.values.password}
								/>
							</div>
							{touched.password && errors.password && <div className="form-error">{errors.password}</div>}
						</div>
						<div className="form-row">
							<label htmlFor="confirmPassword">Confirmar contraseña</label>
							<div className="input-container">
								<input
									className="confirmPassword"
									type="password"
									id="confirmPassword"
									name="confirmPassword"
									placeholder="Confirmar contraseña"
									onChange={formik.handleChange}
									value={formik.values.confirmPassword}
								/>
							</div>
							{touched.confirmPassword && errors.confirmPassword && (
								<div className="form-error">{errors.confirmPassword}</div>
							)}
						</div>
					</>
				)}
				<div className="form-row">
					<button className="submit" type="submit" disabled={formik.isSubmitting}>
						{submitTexts[step]}
					</button>
				</div>
			</form>
		</div>
	);
};

export default ForgotPassword;
