import { Modal } from "react-bootstrap";
import "./TermsModal.scss";
import { useUserContext } from "@src/context/UserContext";
import { updateUser } from "@src/services/UserService";

interface TermsModalPropsI {
	show: boolean;
	onHide: () => void;
}

function TermsModal(props: TermsModalPropsI) {
	const { show, onHide } = props;
	const { signOut, userToken, user, updateUserInfo } = useUserContext();

	const accept = () => {
		let data;
		if (user.metadata === null) {
			data = { metadata: { terms: true } };
		} else {
			data = { metadata: { ...user.metadata, terms: true } };
		}

		if (data.metadata.terms) {
			updateUser(user.id, data).then((res) => {
				if (res && res.status === 200) {
					updateUserInfo();
				}
			});
		}
	};

	return (
		<Modal
			className="terms-conditions-modal"
			show={show}
			onHide={onHide}
			aria-labelledby="contained-modal-title-vcenter"
			centered
			backdrop={"static"}
			keyboard={false}
		>
			<Modal.Header>
				<Modal.Title id="contained-modal-title-vcenter">
					<h2>Aviso</h2>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>
					Para poder seguir haciendo uso de nuestra plataforma, lee y acepta términos, condiciones y políticas
					de uso.
				</p>
				<a href={"https://wellness-studio.mx/register/politicas.pdf"} target="_blank">
					Términos y condiciones
				</a>
			</Modal.Body>
			<Modal.Footer className="justify-content-between">
				<button className="btn secondary" onClick={signOut}>
					No acepto, salir
				</button>
				<button className="btn success" onClick={accept}>
					Acepto, continuar
				</button>
			</Modal.Footer>
		</Modal>
	);
}

export default TermsModal;
