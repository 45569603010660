import { useUserContext } from "@src/context/UserContext";
import { UserMetadata } from "@src/Interfaces/User";
import { updateCurrentUser } from "@src/services/UserService";
import { notifyError, notifySuccess } from "@src/utils/NotificationFunctions";
import { useFormik } from "formik";
import { useEffect } from "react";
import { object, string } from "yup";

const YES = "si";
const NO = "no";

type EditUserInfoProps = {
	metadata: UserMetadata;
	hideEdit: () => void;
};

const EditUserInfoSchema = object({
	injury: string().required("Este campo es obligatorio"),
	injuryDescription: string().when("injury", {
		is: YES,
		then: (schema) => schema.required("Es necesario que nos cuentes acerca de tu lesión"),
		otherwise: (schema) => schema,
	}),
	pregnancy: string().required("Este campo es obligatorio"),
	pregnancyPossibleBirthdate: string().when("pregnancy", {
		is: YES,
		then: (schema) =>
			schema
				.required("Es necesario que nos proporciones una posible fecha")
				.test("is-valid-date", "La fecha no puede estar en el pasado", (value) => {
					return value ? new Date(value) >= new Date() : false;
				}),
		otherwise: (schema) => schema,
	}),
});

const EditUserInfo = ({ metadata, hideEdit }: EditUserInfoProps) => {
	const { updateUserInfo } = useUserContext();
	const { injury, pregnancy, injuryDescription, pregnancyPossibleBirthdate } = metadata;

	const formik = useFormik({
		initialValues: {
			injury: injury || NO,
			injuryDescription: injuryDescription || "",
			pregnancy: pregnancy || NO,
			pregnancyPossibleBirthdate: pregnancyPossibleBirthdate || "",
		},
		validationSchema: EditUserInfoSchema,
		onSubmit: async (values) => {
			const data = { metadata: values };
			try {
				const res = await updateCurrentUser(data);
				updateUserInfo(undefined, undefined, res);
				notifySuccess("Información actualizada con éxito.");
				hideEdit();
			} catch (error) {
				console.error(error);
				notifyError(
					"Ha habido un error al intentar actualizar tu información, por favor intenta de nuevo más tarde."
				);
			}
		},
	});

	return (
		<form className="form-control d-flex flex-column gap-4" onSubmit={formik.handleSubmit}>
			<div className="row">
				<label htmlFor="injury" className="fs-14 fw-bold mb-1">
					Lesión
				</label>
				<select
					name="injury"
					id="injury"
					className="form-select fs-14"
					value={formik.values.injury}
					onChange={(e) => {
						const value = e.target.value;
						formik.setFieldValue("injury", value);
						if (value === NO) {
							formik.setFieldValue("injuryDescription", "");
						}
					}}
				>
					<option value={NO}>No</option>
					<option value={YES}>Sí</option>
				</select>
			</div>
			{formik.values.injury === YES && (
				<div className="row">
					<label htmlFor="injuryDescription" className="fs-14 fw-bold mb-1">
						Descripción (lesión)
					</label>
					<input
						className="form-input fs-14"
						name="injuryDescription"
						id="injuryDescription"
						placeholder=""
						type="text"
						value={formik.values.injuryDescription}
						onChange={formik.handleChange}
					/>
					{formik.touched.injuryDescription && formik.errors.injuryDescription && (
						<p className="form-error mt-1">{formik.errors.injuryDescription}</p>
					)}
				</div>
			)}
			<div className="row">
				<label htmlFor="pregnancy" className="fs-14 fw-bold mb-1">
					Embarazo
				</label>
				<select
					name="pregnancy"
					id="pregnancy"
					className="form-select fs-14"
					value={formik.values.pregnancy}
					onChange={(e) => {
						const value = e.target.value;
						formik.setFieldValue("pregnancy", value);
						if (value === NO) {
							formik.setFieldValue("pregnancyPossibleBirthdate", "");
						}
					}}
				>
					<option value={NO}>No</option>
					<option value={YES}>Sí</option>
				</select>
			</div>
			{formik.values.pregnancy === YES && (
				<div className="row">
					<label htmlFor="pregnancyPossibleBirthdate" className="fs-14 fw-bold mb-1">
						Posible fecha de parto
					</label>
					<input
						className="form-input fs-14"
						name="pregnancyPossibleBirthdate"
						id="pregnancyPossibleBirthdate"
						placeholder=""
						type="date"
						value={formik.values.pregnancyPossibleBirthdate.toLocaleString()}
						onChange={formik.handleChange}
					/>
					{formik.touched.pregnancyPossibleBirthdate && formik.errors.pregnancyPossibleBirthdate && (
						<p className="form-error mt-1">{formik.errors.pregnancyPossibleBirthdate}</p>
					)}
				</div>
			)}
			<button className="btn btn primary" type="submit">
				Guardar
			</button>
		</form>
	);
};

export default EditUserInfo;
