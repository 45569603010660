import React from "react";
import { FormikProps } from "formik";
import { SignUpFormValues } from "../types/Types";
import { MdOutlineBloodtype, MdOutlineContactEmergency } from "react-icons/md";
import { IoLogoInstagram } from "react-icons/io";
import { HiDevicePhoneMobile } from "react-icons/hi2";
import { hasError } from "@src/utils/FormikFunctions";

type StepThreeProps = {
	formik: FormikProps<SignUpFormValues>;
};

const StepThree = ({ formik }: StepThreeProps) => {
	return (
		<>
			<div className="form-row">
				<label htmlFor="emergencyContact">Contacto de emergencia</label>
				<div className="input-container">
					<label htmlFor="emergencyContact">
						<MdOutlineContactEmergency />
					</label>
					<input
						id="emergencyContact"
						name="emergencyContact"
						type="text"
						placeholder="Contacto de emergencia"
						onChange={formik.handleChange}
						value={formik.values.emergencyContact}
						aria-invalid={hasError(formik, "emergencyContact") ? "true" : "false"}
					/>
					<div className="required-tooltip">Obligatorio</div>
				</div>
			</div>
			<div className="form-row">
				<label htmlFor="emergencyContactPhone">Celular contacto de emergencia</label>
				<div className="input-container">
					<label htmlFor="emergencyContactPhone">
						<HiDevicePhoneMobile />
					</label>
					<input
						id="emergencyContactPhone"
						name="emergencyContactPhone"
						type="text"
						placeholder="Celular contacto de emergencia"
						onChange={formik.handleChange}
						value={formik.values.emergencyContactPhone}
						aria-invalid={hasError(formik, "emergencyContactPhone") ? "true" : "false"}
					/>
					<div className="required-tooltip">Obligatorio</div>
				</div>
			</div>
			<div className="form-row instagram">
				<label htmlFor="instagram">Instagram</label>
				<div className="input-container">
					<label htmlFor="instagram">
						<IoLogoInstagram />
					</label>
					<input
						id="instagram"
						name="instagram"
						type="text"
						placeholder="Instagram"
						onChange={formik.handleChange}
						value={formik.values.instagram}
						aria-invalid={hasError(formik, "instagram") ? "true" : "false"}
					/>
				</div>
			</div>
			<div className="form-row blood">
				<label htmlFor="bloodType">Tipo de sangre</label>
				<div className="input-container">
					<label htmlFor="bloodType">
						<MdOutlineBloodtype />
					</label>
					<select
						id="bloodType"
						name="bloodType"
						value={formik.values.bloodType}
						onChange={formik.handleChange}
						aria-invalid={hasError(formik, "bloodType") ? "true" : "false"}
					>
						<option value="">Selecciona</option>
						<option value="A+">A+</option>
						<option value="A-">A-</option>
						<option value="B+">B+</option>
						<option value="B-">B-</option>
						<option value="AB+">AB+</option>
						<option value="AB-">AB-</option>
						<option value="O+">O+</option>
						<option value="O-">O-</option>
					</select>
					<div className="required-tooltip">Obligatorio</div>
				</div>
			</div>

			<div className="form-row terms">
				<input
					type="checkbox"
					id="instagramNoTag"
					name="instagramNoTag"
					checked={formik.values.instagramNoTag}
					onChange={formik.handleChange}
				/>
				<label htmlFor="instagramNoTag">No quiero ser etiquetado</label>
			</div>
			<div className="form-row terms">
				<input type="checkbox" name="terms" checked={formik.values.terms} onChange={formik.handleChange} />
				<p>
					Acepto los{" "}
					<a
						href="https://wellness-studio.mx/register/politicas.pdf"
						target="_blank"
						rel="noopener noreferrer"
					>
						términos y condiciones
					</a>
				</p>
			</div>
		</>
	);
};

export default StepThree;
