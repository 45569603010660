import { IUser } from "@src/Interfaces/User";
import axiosInstance from "./AxiosInstance";
type SchedulesFilterT = "cancelled" | "past" | "future" | "wait_list";

export async function fetchMemberships() {
	return await axiosInstance.get(`/membership/getMembershipsByUser`);
}

export async function getScheduledClassesUser(userToken: string, userId: string) {
	return axiosInstance
		.get(`/schedule/next/${userId}`)
		.then((res) => {
			return res;
		})
		.catch((error) => {
			return error;
		});
}

export async function getSchedulesByMembershipAndFilter(
	membershipId: string,
	userId: string,
	scheduleFilter: SchedulesFilterT
) {
	try {
		const response = await axiosInstance.get(`/schedule/${membershipId}/${scheduleFilter}/${userId}`);
		return response.data;
	} catch (error) {
		throw error;
	}
}
export async function getWaitListsByMembership(membershipId: string, userId: string) {
	try {
		const response = await axiosInstance.get(`/users/waiting_list/${membershipId}/${userId}`);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export async function getUserWaitingLists(userToken: string, userId: string) {
	return axiosInstance
		.get(`/users/waiting_list/active`, {
			params: {
				user: {
					id: userId,
				},
			},
		})
		.then((res) => {
			return res;
		})
		.catch((error) => {
			return error.response;
		});
}

export async function changePassword(
	userToken: string,
	passwords: { password: string; new_password: string },
	userId: string
) {
	return axiosInstance
		.post(`/users/change_password`, { ...passwords, userId })
		.then((res: any) => {
			return res;
		})
		.catch((error) => {
			return error.response;
		});
}

export async function updateUser(userId: string, userData: Partial<IUser>) {
	return axiosInstance.put(`/users/${userId}`, userData).then((res) => {
		return res.data;
	});
}

export async function updateCurrentUser(userData: Partial<IUser>) {
	const res = await axiosInstance.put(`/users/currentUser`, userData);
	return res.data;
}

export async function fetchOpenInvoiceByMembership(token: string, membershipId: string, userId: string) {
	return axiosInstance
		.get(`/invoices/client/${userId}/${membershipId}`)
		.then((res) => {
			return res;
		})
		.catch((error) => {
			throw error;
		});
}

export async function getNotifications(token: string, userId: string) {
	return axiosInstance
		.get(`/notifications/${userId}/notifications`)
		.then((res) => {
			return res;
		})
		.catch((error) => {
			throw error;
		});
}

export const verifyAttendanceByUser = async (userId: string, scheduleId: string) => {
	return axiosInstance
		.get(`/schedule/alumn/check-class-attendance-by-qr?userId=${userId}&scheduleId=${scheduleId}`)
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			throw error;
		});
};
