import { FormikProps } from "formik";
import { SignUpFormValues } from "../types/Types";
import { MdOutlinePersonalInjury, MdPregnantWoman } from "react-icons/md";
import { BsCalendarDate } from "react-icons/bs";
import { LiaAllergiesSolid } from "react-icons/lia";
import { hasError } from "@src/utils/FormikFunctions";

type StepTwoProps = {
	formik: FormikProps<SignUpFormValues>;
};

const YES = "si";
const NO = "no";

const StepTwo = ({ formik }: StepTwoProps) => {
	return (
		<>
			<div className="form-row">
				<label htmlFor="injury">Lesión o padecimiento</label>
				<div className="input-container">
					<label htmlFor="injury">
						<MdOutlinePersonalInjury />
					</label>
					<select id="injury" name="injury" value={formik.values.injury} onChange={formik.handleChange}>
						<option value={NO}>No</option>
						<option value={YES}>Sí</option>
					</select>
					<div className="required-tooltip">Obligatorio</div>
				</div>
			</div>
			{formik.values.injury === NO && <div className="form-row"></div>}
			{formik.values.injury === YES && (
				<div className="form-row">
					<label htmlFor="injuryDescription">Cuéntanos sobre tú lesión</label>
					<div className="input-container">
						<label htmlFor="injuryDescription">
							<MdOutlinePersonalInjury />
						</label>
						<input
							id="injuryDescription"
							name="injuryDescription"
							type="text"
							placeholder="Descripción de la lesión"
							onChange={formik.handleChange}
							value={formik.values.injuryDescription}
							aria-invalid={hasError(formik, "injuryDescription") ? "true" : "false"}
						/>
						<div className="required-tooltip">Obligatorio</div>
					</div>
				</div>
			)}
			<div className="form-row">
				<label htmlFor="pregnancy">¿Está embarazada?</label>
				<div className="input-container">
					<label htmlFor="pregnancy">
						<MdPregnantWoman />
					</label>
					<select
						id="pregnancy"
						name="pregnancy"
						value={formik.values.pregnancy}
						onChange={formik.handleChange}
					>
						<option value={NO}>No</option>
						<option value={YES}>Sí</option>
					</select>
					<div className="required-tooltip">Obligatorio</div>
				</div>
			</div>
			{formik.values.pregnancy === NO && <div className="form-row"></div>}
			{formik.values.pregnancy === YES && (
				<div className="form-row">
					<label htmlFor="pregnancyPossibleBirthdate">Fecha posible de parto</label>
					<div className="input-container">
						<label htmlFor="pregnancyPossibleBirthdate">
							<BsCalendarDate />
						</label>
						<input
							id="pregnancyPossibleBirthdate"
							name="pregnancyPossibleBirthdate"
							type="date"
							placeholder="Descripción de la lesión"
							onChange={formik.handleChange}
							value={formik.values.pregnancyPossibleBirthdate}
							aria-invalid={hasError(formik, "pregnancyPossibleBirthdate") ? "true" : "false"}
						/>
						<div className="required-tooltip">Obligatorio</div>
					</div>
				</div>
			)}
			<div className="form-row">
				<label htmlFor="allergies">Alergias</label>
				<div className="input-container">
					<label htmlFor="allergies">
						<LiaAllergiesSolid />
					</label>
					<select
						id="allergies"
						name="allergies"
						value={formik.values.allergies}
						onChange={formik.handleChange}
						aria-invalid={formik.touched.allergies && Boolean(formik.errors.allergies)}
					>
						<option value={NO}>No</option>
						<option value={YES}>Sí</option>
					</select>
					<div className="required-tooltip">Obligatorio</div>
				</div>
			</div>
			{formik.values.allergies === NO && <div className="form-row"></div>}
			{formik.values.allergies === YES && (
				<div className="form-row">
					<label htmlFor="allergiesDescription">Cuéntanos sobre tus alergias</label>
					<div className="input-container">
						<label htmlFor="allergiesDescription">
							<LiaAllergiesSolid />
						</label>
						<input
							id="allergiesDescription"
							name="allergiesDescription"
							type="text"
							placeholder="Describe tus alergias"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.allergiesDescription}
							aria-invalid={hasError(formik, "allergiesDescription") ? "true" : "false"}
						/>
						<div className="required-tooltip">Obligatorio</div>
					</div>
				</div>
			)}
		</>
	);
};

export default StepTwo;
