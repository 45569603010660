import {
  fetchMemberships,
  getScheduledClassesUser,
} from "@src/services/UserService";

function useUser() {
  const getUserMemberships = async (userToken: string) => {
    return fetchMemberships();
  };

  const getUserScheduleClasses = async (userToken: string, userId: string) => {
    return getScheduledClassesUser(userToken, userId);
  };



  return {
    getUserMemberships,
    getUserScheduleClasses,
  };
}

export default useUser;
