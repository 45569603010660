import { format } from "date-fns";
import "./UserInformationCard.scss";
import { IUser } from "@src/Interfaces/User";
import { useState } from "react";
import UserInfo from "./components/UserInfo/UserInfo";
import EditUserInfo from "./components/EditUserInfo/EditUserInfo";

type UserInformationCardProps = {
	user: IUser;
};

function UserInformationCard({ user }: UserInformationCardProps) {

	const [edit, setEdit] = useState(false);

	const toggleEdit = () => setEdit((prev) => !prev);

	return (
		<div className="user-additional-information-card">
			<div className="general-info-container general">
				<div className="row">
					<h3>INFORMACIÓN GENERAL</h3>
				</div>
				<div className="row">
					<p>
						<b>Miembro desde: </b> {format(new Date(user?.createdAt), "dd/MM/yyyy")}
					</p>
				</div>
			</div>
			<div className="info-container">
				{edit ? (
					<EditUserInfo metadata={user.metadata} hideEdit={toggleEdit} />
				) : (
					<UserInfo metadata={user.metadata} />
				)}
				<button className={`btn ${edit ? "secondary" : "primary"}`} onClick={toggleEdit}>
					{edit ? "Cancelar" : "Editar"}
				</button>
			</div>
		</div>
	);
}

export default UserInformationCard;
